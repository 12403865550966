// 登录跳转链接
export const docUrl = 'http://doc.geekcode.cloud/docs/introduction';//云IDE文档
export const IssuesURL = 'https://github.com/gcodecloud/geekcode.cloud/issues' //issues
export const geekLamp = ' https://geekrank.xyz';//代码面试
export const geekcodeCloud = 'https://ode.gcode.dev/login/' //云IDE
export const geekcodeGit = 'https://ode.gcode.dev/' //云IDEgit
export const GithubURL = 'https://github.com/gcodecloud' //gitHub
export const BBSURL = 'https://github.com/gcodecloud/geekcode.cloud/wiki'//文档
export const Twitter = 'https://twitter.com/GeekCodeCloud' 
export const Medium = 'https://medium.com/@geekcode'
export const gitHubGear = window.location.href+'#https://github.com/gear-tech/gear'

// 点击按钮，跳转到指定位置
function elementPosition(obj) {
  let curleft = 0,
    curtop = 0
  if (obj.offsetParent) {
    curleft = obj.offsetLeft
    curtop = obj.offsetTop
    while ((obj = obj.offsetParent)) {
      curleft += obj.offsetLeft
      curtop += obj.offsetTop
    }
  }
  return { x: curleft, y: curtop }
}
export function ScrollToControl(id) {
  let elem = document.getElementById(id)
  let scrollPos = elementPosition(elem).y
  scrollPos = scrollPos - document.documentElement.scrollTop
  let remainder = scrollPos % 50
  let repeatTimes = (scrollPos - remainder) / 50
  ScrollSmoothly(scrollPos, repeatTimes)
  window.scrollBy(0, remainder)
}
let repeatCount = 0
let cTimeout
function ScrollSmoothly(scrollPos, repeatTimes) {
  if (repeatCount < repeatTimes) {
    window.scrollBy(0, 50)
  } else {
    repeatCount = 0
    clearTimeout(cTimeout)
    return
  }
  repeatCount++
  cTimeout = setTimeout(()=>{
    ScrollSmoothly(scrollPos, repeatTimes)
  },10)
}
